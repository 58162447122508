.heading-disbursement{
    font-size: 10px;
    margin-top: 5px;
    color: #303641;
  }
  
  .para-disbursement{
    font-size: 8px;
    margin-top: 5px;
    color: #303641;
  }
  
  .num-disbursement{
    color: #303641;
    font-size: 15px;
    font-weight: bold;
  }