
.field-name {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #737373;
}

.field-value {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e2e;
    margin-top:6px;
}
.field-value-wrapper{
    margin-top:24px;
    position: relative;
}
.edit-button-wrapper{
    position: absolute;
    top: 0;
    right: 100px;
}
.select-form-wrapper{
    width:40%;
}
