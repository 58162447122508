.profile-details-tab {
  margin-left: 1.5rem;
  margin-bottom: 8.2rem;
}

.profile-details-tab ul {
  margin-bottom: 0 !important;
}
.add-user-call-log-custom{
  padding: 10px 16px 10px 26px;
  border-radius: 6px;
  background-color: #f1f9ff;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-user-call-text-custom{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737373;
}
.add-user-call-log-button-custom{
  padding: 6px 10px 5px;
  border-radius: 4px;
  border: solid 1px #002a4c;
  background-color: #f1f9ff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #002a4c;
}
.profile-details-tab ul li {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #737373;
  margin-right: 1.8rem;
  padding: 0 2rem;
  padding-bottom: 1.4rem;
}

.profile-details-tab ul li:hover {
  cursor: pointer;
}

.profile-details-tab-active {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2e2e2e !important;
  border-bottom: 2px solid #737373;
}

.profile-details-table {
  border-radius: 6px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ebebeb;
}

.profile-details-head {
  padding: 3.1rem !important;
}

.profile-details-head tr th {
  font-size: 1rem;
  font-weight: 600;
  color: #2e2e2e;
  border-bottom: none;
}

.profile-details-body tr {
  border-radius: 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.profile-other-docs td img {
  cursor: pointer;
}

.profile-identity-check {
  font-size: 1.2rem;
  font-weight: 500;
  color: #002a4c;
}

.profile-add-call-log-history {
  border-radius: 6px;
  background-color: #f1f9ff;
}

.profile-add-call-log-history th label {
  font-size: 1.2rem;
  font-weight: 500;
  color: #737373;
  padding: 1rem 1.6rem;
}

.profile-add-call-log-history th button {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  color: #002a4c;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  border: solid 1px #002a4c;
  background-color: #f1f9ff;
  float: right;
  margin-right: 1.5rem;
}

.profile-financial-check td,
.profile-other-docs td,
.profile-call-logs td,
.profile-loan-info td {
  font-size: 1.2rem;
  font-weight: 500;
  color: #737373;
}

.profile-details-body tr td {
  color: #737373;
  font-size: 12px;
  font-weight: 500;
}

.profile-details-tr-fade {
  color: #737373 !important;
}

.profile-details-tr-bright {
  color: #002a4c !important;
  text-decoration: none !important;
}

.profile-details-tr-number {
  color: #2e2e2e !important;
}

.green-status-btn {
  font-size: 1.2rem;
  font-weight: 500;
  color: #28a745 !important;
  background-color: #e9ffee;
  border-radius: 4px;
  padding: 0.5rem 1.1rem;
}

.red-status-btn {
  font-size: 1.2rem;
  font-weight: 500;
  color: #dc3545 !important;
  border-radius: 4px;
  background-color: #fff1f2;
  padding: 0.5rem 1.1rem;
}

.table thead th {
  border-bottom: none !important;
}

.custom-dropdown {
  border-radius: 4px !important;
  border: solid 1px #dfdfdf !important;
  display: inline-block !important;
}

.custom-dropdown .btn-light:hover {
  border-color: #ffffff;
}

.custom-dropdown:focus {
  outline: none !important;
}

.btn-light:active {
  outline: none !important;
  border-color: #ffffff !important;
}

.custom-dropdown {
  min-width: 90px;
  border-radius: 4px !important;
  border: solid 1px #dfdfdf !important;
  display: inline-block !important;
}

.custom-dropdown button {
  border: none;
}

.profile-details-tab .personal-user-type-dropdown .dropdown-toggle {
  min-width: 100px !important;
}

.table-name-profile-tab-contanier{
  display: flex;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.table-name-profile-tab-contanier::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.status-fail-anyalisis {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545;
  text-align: center;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #dc3545;
}
.status-pass-anyalisis {
  font-size: 12px;
  font-weight: 500;
  color: #28a745;
  text-align: center;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #28a745;
}
.status-initiated-anyalisis{
  font-size: 12px;
  font-weight: 500;
  color: #00a3ff;
  text-align: center;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #00a3ff;
}
/* MEDIA QUERIES */

@media only screen and (max-width: 1120px) {
  .profile-details-tab ul li {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 4000px) {
  .other-docs-action-icon {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .profile-details-tab ul li {
    margin-bottom: 1rem;
    margin-right:4px;
  }
}