.title {
    font-size: large;
    font-weight: 600;
    color: #2e2e2e;
    padding-bottom: 0.6rem;
}

.chip-card {
    border: 1px solid #448AFA;
    border-radius: 8px;
    padding: 2.25rem;
    top: 10px;
}

.chip-card:hover {
    background-color: #ebe5e5;
}

.card-text{
    font-size: medium;
}

.chip {
  background: #fff;
  border: 2px solid #ebebeb;
  border-radius: 8px;
  height: 120px;
  width: 220px;
}
  
  .chip-body img{
    position: absolute;
    right: -6px;
    bottom: -20px;
    width: 121px;
    opacity: 0.05;
  }
  
  .chip-body {
    position: relative;
    display: block;
    padding: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 5px;
    -moz-background-clip: padding;
    border-radius: 5px;
    background-clip: padding-box;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  
  .heading{
    font-size: 14px;
    margin-top: 5px;
    color: #303641;
  }
  
  .para{
    font-size: 11px;
    margin-top: 5px;
    color: #303641;
  }
  
  .num{
    color: #303641;
    font-size: 20px;
    font-weight: bold;
  }

  @media only screen and (max-width: 1400px) {
  
    .chip {
      background: #fff;
      border: 2px solid #ebebeb;
      border-radius: 8px;
      height: 120px;    
      width: 180px;
    }
  }