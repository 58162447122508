.product {
    position: relative;
  }
  
  .product .price {
    text-decoration: line-through; /* Apply strikethrough to original price */
    color: #999999;
  }
  
  .product .discount {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background-color: #ff0000; /* Set the background color to red */
    color: #ffffff; /* Set the text color to white */
    font-weight: bold;
    font-size: 14px;
  }