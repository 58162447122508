.repayment-sec-heading h2 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
}

.repayment-sec-heading {
  margin-bottom: 14px;
}
.repayment-sec-table {
  width: 100%;
  overflow-x: scroll;
}
.repayment-sec-table .table-responsive {
  border-radius: 5px;
  /* border: solid 1px #e9e9e9; */
  background-color: #ffffff;
}

.repayment-sec-table .table-responsive .table {
  margin: 0px;
}

.repayment-sec-table .table-responsive .table thead tr th {
  font-size: 10px;
  font-weight: 600;
  color: #2e2e2e;
  min-width: 110px;
}

.repayment-sec-table .table-responsive .table tbody tr td {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
  min-width: 110px;
  vertical-align: middle;
}

.repayment-sec-table .table-responsive .table tbody tr td .label-status-paid {
  font-size: 12px;
  font-weight: 500;
  color: #28a745;
  text-align: center;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #28a745;
}

.repayment-sec-table .table-responsive .table tbody tr td .status-unpaid {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545;
  text-align: center;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #dc3545;
}

.repayment-sec-table .table-responsive .table tbody tr .repayment-aling {
  vertical-align: top;
}

.table-responsive {
  overflow-x: initial !important;
}

.profile-details-tab .table-responsive {
  overflow: auto;
}

.profile-details-tab .table-responsive::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.loan-info-repay {
  margin-top: 40px;
}

.loan-payment-auto-manual {
  margin-top: 40px;
}

.loan-info-sign {
  margin-top: 40px;
}

.loan-payment-auto-manual-container {
  padding: 18px 23px 20px 19px;
  border-radius: 6px;
  border: solid 1px #f2e2b5;
  background-color: #fff7de;
}

.loan-payment-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2e2e2e;
}


.loan-payment-button-wrapper {
  margin-top: 20px;
}

.manual-payment-modal-container {
  padding: 6px 34px 42px 34px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}
.auto-payment-modal-container {
  padding: 6px 34px 0px 34px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}
.close-loan-modal-container{
  padding: 6px 34px 42px 34px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}


.loan-modal-close-button {
  position: absolute;
  right: 27px;
  top: -36px;
  cursor: pointer;
}

.loan-modal-title {
  margin-top: 22px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #002a4c;
}
.tooltip-loan{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.tooltip-loan:hover{
  white-space: normal;
  overflow: auto;
  position: relative;
}
.loan-modal-overlay-background {
  background-color: rgba(0, 42, 76, 1)!important;
}
.manual-payment-modal-dialoag{
  max-width: 600px;
  margin-top:100px!important;
  margin-bottom:100px!important;
}
.auto-payment-modal-dialoag{
  max-width: 600px;
  margin-bottom:100px!important;
}

.close-loan-payment-modal-dialoag{
  max-width: 600px;
  margin-bottom:100px!important;
}
.loan-modal-title{
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #002a4c;
}
.manual-payment-modal-button-container{
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
}
.close-loan-modal-button-container{
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
}
.close-button-wrapper{
  position: absolute;
  right:20px;
  top:-15px;
}
.close-loan-repayment-container{
  position: relative;
}
.auto-payment-form-field-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:20px 0;
  padding-bottom: 20px;
  border-bottom: solid 1px #ebebeb;
}
.auto-payment-form-field-wrapper>label{
  width:120px;
}
.auto-payment-form-field-wrapper> div{
 padding-top: 20px;
}
.auto-payment-form-field-wrapper> input{
  max-width: 170px;
  height: 36px;
  margin-bottom:20px;
}
.auto-payment-form-field-wrapper> button{
  width: 100px;
  height: 34px;
  padding: 10px 14px 9px 15px;
  border-radius: 4px;
  border: solid 1px #002a4c;
  background-color: #ffffff;
}
.auto-payment-no-border-bottom{
  border-bottom:none ;
}

@media only screen and (max-width: 1200px) {
  .loan-tabel ul li {
    display: block;
  }
  .loan-tabel ul li span {
    display: block;
  }
  .loan-tabel ul li label {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .loan-tabel ul li {
    display: flex;
  }
  .loan-tabel ul li span {
    display: block;
  }
  .loan-tabel ul li label {
    display: block;
  }
}